<template>
  <div class="main">
    <div class="header" v-if="info.ext&&info.ext.headUrl">
      <div style="display: flex;align-items: center">
        <van-icon @click="handleBack" size="23" class="left-icon" name="arrow-left"/>
        <img @click="$router.push({path:'/user',query:{id:info.ext.id}})" class="avatar" width="37"
             height="37" v-lazyload :data-src="info.ext.headUrl"/>
        <div class="des">
          <div class="des-name">{{ info.ext.nickName }}</div>
          <div class="des-school">
            <van-icon v-if="info.ext.certification" color="#06f" style="padding-left: 7px;" name="wechat-pay"/>
          </div>
        </div>
        <div @click="handleSubscription" class="header-right">
          <van-icon color="#1989fa" name="plus" />
          订阅
        </div>
      </div>
<!--      <div class="follow" @click="showShare = true">
        <van-icon name="share"/>
        分享
      </div>-->
    </div>
    <div class="content">
      <div class="content-title">
        {{ info.title }}
      </div>
      <van-divider/>
      <div class="task-detail">
        <div class="task-item">
          <div class="item-label">
            分类:
          </div>
          <div class="item-content">{{ info.categoryName }}</div>
        </div>
        <div class="task-item">
          <div class="item-label">
            任务期限:
          </div>
          <div class="item-content">{{ info.overTime }}</div>
        </div>
        <div class="task-item">
          <div class="item-label">
            任务天数:
          </div>
          <div class="item-content">{{ info.overTime }}</div>
        </div>
        <div class="task-item">
          <div class="item-label">
            任务分类:
          </div>
          <div class="item-content">{{info.jobType==1?'普通任务':'长期任务'}}</div>
        </div>
        <div class="task-item">
          <div class="item-label">
            回复数据类型:
          </div>
          <div class="item-content">{{info.jobDataType==1?'图文':(info.jobDataType==2?'视频':'任意') }}</div>
        </div>
        <div class="task-item">
          <div class="item-label">
            回复人性别:
          </div>
          <div class="item-content">{{ info.sex==0?'不限':(info.sex==1?'男':'女') }}</div>
        </div>
        <div class="task-item">
          <div class="item-label">
            打赏金额:
          </div>
          <div class="item-content">{{ info.rewardsCoin }}</div>
        </div>

      </div>
      <div class="content-main">
        <MarkdownContent :content="info.content"/>
      </div>
      <div class="content-time">发布于 {{ info.createTime }} · 未经作者允许禁止转载</div>
      <div class="reply-wrap">
        <van-button @click="$router.push({path:'/reply',query:{type:info.jobDataType,id:$route.params.id}})" size="mini" icon="comment" type="info">挑战</van-button>
        <a @click="updateFavorite" class="reply-btn">
          <van-icon v-if="!info.isFav" size="20" name="star-o" />
          <van-icon v-if="info.isFav" color="#0066ff" size="20" name="star" />
          {{info.isFav?'已收藏':'收藏'}}
        </a>
        <a @click="showShare=true" class="reply-btn">
          <van-icon size="20" name="share-o" />
          分享
        </a>
      </div>
      <div>
        <div style="letter-spacing: 1px;padding-bottom: 20px">已接受任务 {{ commentList.length }}
        </div>
        <div v-for="(item, index) in commentList" :key="index" class="comments-item">
          <img @click.stop="$router.push({path:'/user',query:{id:item.ext.id}})" width="20" height="20" style="border-radius: 10px" v-lazyload :key="item.id" :data-src="item.ext.headUrl" />
          <div style="margin-left: 15px;flex: 1">
            <div @click="$router.push({path:'/detail',query:{id:item.id}})" class="comments-item-d1">{{ item.account }}</div>
            <div @click="$router.push({path:'/detail',query:{id:item.id}})" class="comments-item-d2">{{ item.content }}</div>
            <div @click="$router.push({path:'/detail',query:{id:item.id}})" class="comments-item-d3" style="margin-bottom: 15px">
              <div class="comments-item-d3-l">
                {{ item.createTime }}
              </div>
              <div class="comments-item-d3-r">
                <span @click="$router.push({path:'/reply',query:{type:info.jobDataType,id:$route.params.id}})"><van-icon size="16" name="chat-o" />{{item.clickCount}}</span>
                <span><van-icon size="16" name="like-o" />{{item.zanCount}}</span>
                <span v-if="!item.isPay" @click.stop="unlock(item)"><van-icon size="16" name="bag-o" />购买</span>
              </div>
            </div>
<!--            <div @click="$router.push({path:'/detail',query:{id:it.id}})"  v-for="(it, i) in commentList.slice(0,2)" :key="it.id" class="comments-item">
              <p>DAY{{i+1}}</p>
              <div style="margin-left: 15px;flex: 1">
                <div class="comments-item-d1">{{ it.account }}</div>
                <div class="comments-item-d2">{{ it.content }}</div>
                <div class="comments-item-d3">
                  <div class="comments-item-d3-l">
                    {{ it.createTime }}
                  </div>
                  <div class="comments-item-d3-r">
                    <span><van-icon size="16" name="chat-o" />{{it.clickCount}}</span>
                    <span><van-icon size="16" name="like-o" />{{it.zanCount}}</span>
                    <span v-if="!it.isPay" @click.stop="unlock(it)"><van-icon size="16" name="bag-o" />购买</span>
                  </div>
                </div>
              </div>
            </div>-->
<!--            <div @click="viewMore(item,commentList)" class="more">
              <span>详情<van-icon size="16" name="arrow" /></span>
            </div>-->
          </div>
        </div>
      </div>
      <div @click="show=true" class="more">
        <span>更多评论<van-icon size="16" name="arrow" /></span>
      </div>
    </div>

<!--    <div class="bottom-bar">
      <van-button :loading="agreeLoading"
                  loading-text="加载中..."
                  round type="info"
                  @click="agree">
        <div style="display: flex">
          <van-icon class="bottom-bar-icon" size="20" name="play"/>
          <span class="s1">赞同 {{ info.clickCount }}</span>
        </div>
      </van-button>
      <a @click="updateFavorite" style="float: right">
        <div class="comments-count">{{ info.favCount }}条收藏</div>
        <van-icon class="comments-icon" v-if="info.isFav" name="star" color="#1989fa" size="25"/>
        <van-icon v-else class="comments-icon" color="#909399" size="25" name="star-o"/>
      </a>
      <a @click="show = true" style="float: right">
        <div class="comments-count">{{ info.replyCount }}条评论</div>
        <van-icon color="#909399" size="25" class="comments-icon" name="chat-o"/>
      </a>
    </div>-->
    <van-popup  closeable
               close-icon="clear"
               v-model="show"
               round
               position="bottom"
               :style="{ height: '90%' }">
      <div style="padding: 15px">
        <div class="popup-title">全部回复</div>
        <div class="popup-content">
          <div style="padding-top: 10px;letter-spacing: 1px;padding-bottom: 20px">评论 {{ commentList.length }}
          </div>
          <div v-for="(item, index) in commentList" :key="index" class="comments-item">
            <img @click.stop="$router.push({path:'/user',query:{id:item.ext.id}})"  width="20" height="20" style="border-radius: 10px" v-lazyload :key="item.id" :data-src="item.ext.headUrl" />
            <div style="margin-left: 15px;flex: 1 1 0%;">
              <div @click="$router.push({path:'/detail',query:{id:item.id}})"  class="comments-item-d1">{{ item.account }}</div>
              <div @click="$router.push({path:'/detail',query:{id:item.id}})"  class="comments-item-d2">{{ item.content }}</div>
              <div class="comments-item-d3">
                <div class="comments-item-d3-l">
                  {{ item.createTime }}
                </div>
                <div class="comments-item-d3-r">
                  <span @click="$router.push({path:'/reply',query:{type:info.jobDataType,id:$route.params.id}})">
                    <van-icon size="16" name="chat-o" />{{item.clickCount}}</span>
                  <span><van-icon size="16" name="like-o" />{{item.zanCount}}</span>
                  <span v-if="!item.isPay" @click.stop="unlock(item)"><van-icon size="16" name="bag-o" />购买</span>
                </div>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="popup-bottom">
          <label>
            <input readonly placeholder="发一条友善的评论" v-model="commentForm.content" class="bottom-bar-input"/>
          </label>
          <div class="popup-bottom-send">发布</div>
        </div>-->
      </div>
    </van-popup>
    <van-popup closeable close-icon="clear" v-model="showMore" round position="bottom" :style="{ height: '90%' }">
      <div style="padding: 15px" v-if="replyInfo.ext">
        <div class="popup-title">全部评论</div>
        <div class="popup-content">
          <div class="task-detail">
            <div class="detail-top">
              <div class="top-left">
                <img @click.stop="$router.push({path:'/user',query:{id:replyInfo.ext.id}})" v-lazyload :data-src="replyInfo.ext.headUrl" class="avatar" />
                <div class="nickname">
                  <h3>{{replyInfo.ext.nickName}}</h3>
                </div>
              </div>
              <div @click="updateFocus" class="top-right">
                <van-icon color="#1989fa" name="plus" />
                关注
              </div>
            </div>
          </div>
          <div class="comments-item" v-for="(it, i) in commentList.slice(0,2)" :key="it.id">
            <p>DAY{{i+1}}</p>
            <div style="margin-left: 15px;flex: 1">
              <div @click="$router.push({path:'/detail',query:{id:it.id}})" class="comments-item-d1">{{ it.account }}</div>
              <div @click="$router.push({path:'/detail',query:{id:it.id}})" class="comments-item-d2">{{ it.content }}</div>
              <div @click="$router.push({path:'/detail',query:{id:it.id}})" class="comments-item-d3" style="margin-bottom: 15px">
                <div class="comments-item-d3-l">
                  {{ it.createTime }}
                </div>
                <div class="comments-item-d3-r">
                  <span @click="$router.push({path:'/reply',query:{type:info.jobDataType,id:$route.params.id}})"><van-icon size="16" name="chat-o" />{{it.clickCount}}</span>
                  <span><van-icon size="16" name="like-o" />{{it.zanCount}}</span>
                  <span v-if="!it.isPay" @click.stop="unlock(replyInfo)"><van-icon size="16" name="bag-o" />购买</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </van-popup>
    <van-share-sheet
        v-model="showShare"
        title="立即分享给好友"
        :options="options"
    />
  </div>
</template>

<script>
import MarkdownContent from "@/components/MarkdownContent";
import {agree, findArticleById} from "@/api/article";
import {getPostForm, updateFave,unlockReply,subscriptionUser} from "@/api/user";
import {BASE_RUL} from "@/utils/request";
import {findAllComment, saveComment} from "@/api/comment";
import {Toast,Dialog} from "vant";

export default {

  components: {MarkdownContent},

  data() {
    return {
      showShare: false,
      options: [
        {name: '微信', icon: 'wechat'},
        {name: '微博', icon: 'weibo'},
        {name: '复制链接', icon: 'link'},
        {name: '分享海报', icon: 'poster'},
        {name: '二维码', icon: 'qrcode'},
      ],
      agreeLoading: false,
      commentForm: {
        uid: localStorage.getItem("uid"),
        aid: this.$route.params.id,
        content: ''
      },
      commentList: [],
      base: BASE_RUL,
      data: {
        article: {},
        author: {},
      },
      info: {},
      show: false,
      formData: {},
      page:1,
      showMore:false,
      dayList:[],
      replyInfo:{}
    }
  },

  mounted() {
    this.getTaskInfo()
    getPostForm({}).then((res) => {
      if (res.code == 0) {
        this.formData = res.result
        this.tagList = res.result.jobDataType
      }
    })
  },

  methods: {

    handleBack() {
      this.$router.back()
    },
    getTaskInfo() {
      findArticleById({id: this.$route.params.id}).then((res) => {
        if (res.code == 0) {
          this.info = res.result
        }
      })
      findAllComment({
        "page":this.page,
        "limit": "10",
        "jobId": this.$route.params.id,
        "sort": "1"
      }).then((res) => {
        if (res.code == 0) {
          this.commentList = res.result.records
        }
      })
    },
    submitComment() {
      saveComment({
        "jobId": this.$route.params.id,
        "requestId": this.formData.requestId,
        "jobDataType": "1",
        "content": this.commentForm.content
      }).then((res) => {
        if (res.code == 0) {
          this.commentList.push(res.data)
          this.data.article.commentCount = this.data.article.commentCount + 1
          this.$toast.success("感谢你的评论")
        }
      })
    },

    agree() {
      this.agreeLoading = true
      agree({id: this.$route.params.id}).then((res) => {
        if (res.status) {
          this.data.article.agreeCount = this.data.article.agreeCount + 1
        }
        setTimeout(() => {
          this.$toast.success("谢谢你的赞同")
          this.agreeLoading = false
        }, 700)
      })
    },
    updateFavorite() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      updateFave({
        "contentId": this.$route.params.id,
        "type": "1"
      }).then((res) => {
        if (res.code == 0) {
          this.getTaskInfo()
        }
      })
    },
    unlock(item){
      Dialog.confirm({
        title: '提示',
        message: '购买该回复需要'+item.price+'钻石,是否购买?',
      }).then(() => {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        unlockReply({id:item.id}).then((res)=>{
          this.getTaskInfo()
        })
          })
          .catch(() => {
            // on cancel
          });
    },
    viewMore(item,list){
      this.dayList=list
      this.showMore=true
      this.replyInfo=item
    },
    updateFocus(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      updateFave({
        "contentId":this.info.id,
        "type": "1"
      }).then((res) => {
        if (res.code == 0) {
          this.getTaskInfo()
        }
      })
    },
    handleSubscription(){
      Dialog.confirm({
        title: '标题',
        message: '是否订阅该用户?',
      }).then(() => {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        subscriptionUser({subUserId:this.info.ext.id}).then((res)=>{
          this.getTaskInfo()
        })
      })
    }
  },

}
</script>

<style scoped>
.header {
  width: 100%;
  height: 70px;
  background: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 10;
}

.left-icon {
  padding-top: 23px;
  padding-left: 15px;
  margin-right: 20px;
}

.avatar {
  margin-top: 16px;
}

.des {
  font-size: 12px;
  padding-top: 13px;
  padding-left: 10px;
  letter-spacing: 1px;
  width: 47vw;
}

.des-name {
  font-size: 15px;
  color: rgba(0, 0, 0, .8);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 可以显示的行数，超出部分用...表示*/
  -webkit-box-orient: vertical;
}

.des-school {
  display: flex;
  padding-top: 5px;
  font-size: 12px;
  color: rgba(0, 0, 0, .5);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 可以显示的行数，超出部分用...表示*/
  -webkit-box-orient: vertical;
}

.follow {
  float: right;
  margin-top: -28px;
  margin-right: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  color: #0066ff;
}

.content {
  padding: 15px;
  padding-top: 70px;
}

.content-title {
  padding-top: 10px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 2px;
}

.content-main {

}

.content-time {
  font-size: 13px;
  color: rgba(0, 0, 0, .4);
  letter-spacing: 1px;
  padding: 20px 0;
}

.bottom-bar {
  width: 100%;
  height: 45px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  padding: 10px;
}

.bottom-bar-icon {
  transform: rotate(-90deg);
}

.s1 {
  font-size: 15px;
  letter-spacing: 2px;
  padding-left: 10px;
}

.comments-icon {
  float: right;
  padding-right: 5px;
  padding-top: 8px;
}

.comments-count {
  float: right;
  padding-right: 25px;
  color: #909399;
  letter-spacing: 1px;
  padding-top: 11px;
  font-size: 13px;
}

.popup-content {
  overflow-x: hidden;
  overflow-y: scroll;
}

.popup-content ::-webkit-scrollbar {
  display: none !important;
}

.popup-title {
  text-align: center;
  letter-spacing: 1px;
  font-size: 17px;
  font-weight: bold;
}

.popup-bottom {
  width: 100%;
  height: 70px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 99;
  background: #ffffff;
  bottom: 0;
  left: 0;
}

.popup-bottom-send {
  letter-spacing: 3px;
  float: right;
  color: #0066ff;
  line-height: 70px;
  font-weight: 400;
  font-size: 15px;
  padding-right: 15px;
}

.bottom-bar-input {
  height: 40px;
  font-size: 13px;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, .4);
  margin-top: 13px;
  margin-left: 20px;
  width: 70%;
  border: none;
}

.comments-item {
  display: flex;
  margin-bottom: 20px;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 20px;
}

.comments-item-d1 {
  color: rgba(0, 0, 0, .9);
}

.comments-item-d2 {
  color: rgba(0, 0, 0, .7);
  padding: 4px 0;
}

.comments-item-d3 {
  color: rgba(0, 0, 0, .4);
  font-size: 11px;
  display: flex;
  justify-content: space-between;
}

.header .avatar {
  border-radius: 17.5px;
}

.task-detail{
  margin-bottom: 10px;
}

.task-detail .task-item {
  display: flex;
  line-height: 30px;
  position: relative;
}

.task-detail .task-item::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
  content: '';
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  box-sizing: border-box;
  height: 1px;
  border-width: 1px 0 0;
  color: #969799;
  font-size: 14px;
  line-height: 24px;
  border-color: #ebedf0;
  border-style: solid;
}

.task-detail .task-item .item-label {
  width: 30%;
  text-align: right;
  padding-right: 5px;
}
.more{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, .4);
}
.comments-item-d3-r span{
  margin-left: 10px;
}
.reply-wrap{
  display: flex;
  border-top: 1px dashed #CDCDCD;
  padding: 10px 0;
  font-size: 14px;
  gap: 20px;
}
.reply-wrap a{
  display: flex;
  align-items: center;
  gap: 5px;
}
.task-detail{
  margin-bottom: 10px;
}

.task-detail .task-item {
  display: flex;
  line-height: 30px;
  position: relative;
}

.task-detail .task-item::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
  content: '';
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  box-sizing: border-box;
  height: 1px;
  border-width: 1px 0 0;
  color: #969799;
  font-size: 14px;
  line-height: 24px;
  border-color: #ebedf0;
  border-style: solid;
}

.task-detail .task-item .item-label {
  width: 30%;
}
.content-main .article{
  margin-bottom: 20px;
}
.detail-top{
  display: flex;
  justify-content: space-between;
}
.top-left{
  display: flex;
  align-items: center;
  gap: 10px;
}
.top-left .avatar{
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-top: 0;
}
.top-left h3{
  margin: 0;
  font-size: 16px;
}
.top-left .nickname{
  display: flex;
  align-items: center;
}
.top-right{
  color: #1989fa;
  display: flex;
  align-items: center;
}
.header-right{
  color: #1989fa;
  margin-top: 16px;
}
</style>
