import {post,get,getBase64} from "@/config/http";
export function findAllComment(data) {
    console.log(data,'80a80dqe')
    return post(
        '/answer/listByJob',
        data
    )
}

export function saveComment(data) {
    console.log(data,'8a80dsad')
    return post(
        '/user/answer/reply',
        data
    )
}
